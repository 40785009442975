
const enumType = () =>{
    var retType={}
    
    retType[retType["上門取件"] = 1] = "上門取件";
    retType[retType["自行寄送"] = 2] = "自行寄送";
    retType[3] = ["上門取件", "自行寄送"];

    return retType
}
export default {
    enumType
}