//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { Image, Select, Option, OptionGroup, Input, Upload, Message, Cascader, Form, FormItem, Icon, Button, Dialog, Tag } from "element-ui";
import { ctrlOrder, ctrlAddr, ctrlBase } from "@/controller";
import { GoodsItem, CouponItem } from "@/components";
import type from "@/enumType";
export default {
    components: {
        [Button.name]: Button,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
        [Cascader.name]: Cascader,
        [OptionGroup.name]: OptionGroup,
        [Option.name]: Option,
        [Select.name]: Select,
        [Upload.name]: Upload,
        [Image.name]: Image,
        [Icon.name]: Icon,
        [Dialog.name]: Dialog,
        [Tag.name]: Tag,
        GoodsItem,
        CouponItem,
    },
    data() {
        return {
            showElement: false,
            info: [], //訂單詳情
            showHeadbar: true,

            form: {
                reason: "",
                desc: "",
                imgFile: "",
                cityId: "", //取件地址id
                city: "", //取件地址
                receiverNameOrig: "", //收件人名字
                receiverTelOrig: "", //收件人電話
                receiverAddr: "", //收件人詳情地址
                retTypeName: "", //寄送原因
                retType: "", //寄送原因key
            },

            showPicker: false,
            showCityPicker: false,
            rsnList: [], //退貨原因列表
            areaList: [], //地址列表
            retTypeList: [], //寄送方式列表
            submitbar: false,
            partInfo: {}, //部分退貨數據
            cpnSetPop: false,
        };
    },
    created() {
        this.getDetail();
        this.getRsnList();
        this.getAreaList();
    },
    computed: {
        ...mapState({
            //validateMsg
            msg: function(state) {
                return state["validate"];
            },
            configInfo(state) {
                return state.base.configInfo;
            },
        }),
        isPart() {
            return this.$route.query.isPart;
        },
    },
    watch: {
        configInfo: {
            handler() {
                this.getRetTypeList();
            },
            immediate: true,
        },
    },
    mounted() {},
    methods: {
        //獲取訂單詳情
        async getDetail() {
            this.info = await ctrlOrder.getDetail(this.$route.params);

            if (this.Common.isExist(this.info)) {
                //部分退貨
                if (this.isPart) {
                    this.partInfo = await ctrlOrder.getPartRetResult2({ soNbr: this.info.SoInfo.soNbr });
                    if (this.Common.isExist(this.partInfo)) {
                        // for (const item of this.partInfo.RetdList) {
                        //     item.qtyOrd = item.qtyRet;
                        // }
                        this.info.SodList = this.partInfo.RetdList;
                    }
                }
                [this.form.receiverNameOrig, this.form.receiverTelOrig, this.form.receiverAddr] = [this.info.SoInfo.receiverNameOrig, this.info.SoInfo.receiverTelOrig, this.info.SoInfo.receiverAddr];
                this.form.cityId = this.info.SoInfo.receiverCityId.split(",");
                this.form.city = this.info.SoInfo.receiverCity.split(" ");
                // this.cityid = this.form.cityId.toString();
                this.showElement = true;
            }
            console.log(this.partInfo);
            // console.log(this.info);
        },
        //取得退貨原因
        async getRsnList() {
            this.rsnList = await ctrlOrder.getRsnList();
        },
        //獲取城市列表
        async getAreaList() {
            this.areaList = await ctrlAddr.getAreaList();
        },
        //取得寄送方式
        async getRetTypeList() {
            const retType = this.configInfo.retType;
            switch (retType) {
                case 1:
                case 2:
                    this.retTypeList = [type.enumType()[retType]];
                    break;
                case 3:
                    this.retTypeList = type.enumType()[retType];
                default:
                    break;
            }
        },
        async send() {
            this.$refs.returnForm.validate(async (valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                ctrlBase.gaSentEvent("進入退貨", "商品退回", ctrlBase.getPtNameStr(this.info.SodList));
                this.submitbar = true;
                setTimeout(() => {
                    this.submitbar = false;
                }, 1500);
                this.form.retType = type.enumType()[this.form.retTypeName];

                const { reason, desc, imgFile, receiverNameOrig, receiverTelOrig, receiverAddr, cityId, city, retType } = this.form;
                if (!this.isPart) {
                    ctrlOrder.applyRet(this.$route.params.nbr, reason, desc, imgFile, receiverNameOrig, receiverTelOrig, receiverAddr, cityId.toString(), city.toString(), retType);
                } else {
                    let obj = {};
                    let retList = [];
                    for (const item of this.partInfo.RetdList) {
                        obj = {
                            ln: item.ln,
                            qtyRet: item.qtyRet,
                        };
                        retList.push(obj);
                    }
                    ctrlOrder.applyPartRet(this.$route.params.nbr, reason, desc, imgFile, receiverNameOrig, receiverTelOrig, receiverAddr, cityId.toString(), city.toString(), retType, retList);
                }
            });
        },
        adrHandleChange() {},
        handleChange(res, filesList) {
            // for (var i = 0; i < filesList.length; i++) {
            //     const isJPG = filesList[i].raw.type === "image/jpeg";
            //     const isGIF = filesList[i].raw.type === "image/gif";
            //     const isPNG = filesList[i].raw.type === "image/png";
            //     const isBMP = filesList[i].raw.type === "image/bmp";
            //     if (!isJPG && !isGIF && !isPNG && !isBMP) {
            //         this.$refs['uploader'].clearFiles();
            //        return Message.error("上傳圖片必須是JPG/GIF/PNG/BMP 格式!");
            //     }
            // }
            this.form.imgFile = filesList;
        },
        handleRemove(res, file) {
            this.form.imgFile = file;
        },
        exceed() {
            Message.error("上傳圖片數量達到上限!");
        },
        // beforeAvatarUpload(file) {
        //     const isJPG = file.type === "image/jpeg";
        //     const isGIF = filesList[i].raw.type === "image/gif";
        //     const isPNG = filesList[i].raw.type === "image/png";
        //     const isBMP = filesList[i].raw.type === "image/bmp";
        //     const isLt4M = file.size / 1024 / 1024 < 4;
        //     if (!isJPG && !isGIF && !isPNG && !isBMP) {
        //         Message.error("上傳圖片必須是JPG/GIF/PNG/BMP 格式!");
        //     }
        //     if (!isLt4M) {
        //         Message.error("上傳頭像圖片大小不能超過 4MB!");
        //     }
        //     return isJPG && isGIF && isPNG && isBMP && isLt4M;
        // },
    },
};
